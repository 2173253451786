import React, { useState } from 'react';
import {
  // Input as AntInput,
  Select as AntSelect,
  // Space,
  // Switch,
  // InputNumber,
  Button,
  // Radio,
  // Divider,
  Card,
  Form,
  // Table,
} from 'antd';
import { Leg } from './leg';
import {
  Box,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  // TableFooter,
} from '@mui/material';

import { PlusSquareOutlined } from '@ant-design/icons';

const LEG_TEMPLATE = {
  transactionType: 'option',
  optionType: 'ce',
  actionType: 'buy',
  expiryCycle: 'current-week',
};

const LegsList = (props: any) => {
  return (
    <Card
      size="small"
      title="Transaction Details"
      style={props?.style}
    >
      <Box sx={{ margin: '1em' }}>
        <StrikeBasedOnControl {...props} />
      </Box>
      <Box>
        <Legs />
      </Box>
    </Card>
  );
};

const Legs = () => {
  const getTimeStamp = () => {
    return new Date().getTime();
  };
  const [legs, setLegs] = useState<any>([
    { ...LEG_TEMPLATE, id: getTimeStamp() },
  ]);
  const addLeg = (leg: any) => {
    setLegs([...legs, { ...LEG_TEMPLATE, ...leg, id: getTimeStamp() }]);
  };
  const removeLeg = (id: number) => {
    const newLegs = legs.filter((i: any) => i.id !== id);
    setLegs(newLegs);
  };

  const updateLeg = (leg: any) => {
    const newLegs = legs.map((i: any) => {
      if (i.id === leg.id) {
        return leg;
      }
      return i;
    });
    setLegs(newLegs);
    console.log('updated legs data - ', newLegs);
  };

  return (
    <>
      <Box style={{ overflowY: 'hidden', overflowX: 'auto' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction</TableCell>
              <TableCell>Option</TableCell>
              <TableCell>Strike</TableCell>
              <TableCell>Expiry</TableCell>
              <TableCell>Action</TableCell>
              <TableCell>Lots</TableCell>
              <TableCell>Stop Loss</TableCell>
              <TableCell>Take Profit</TableCell>
              <TableCell>Trailing Stop Loss</TableCell>
              <TableCell>ReEntry/ReExecute</TableCell>
              <TableCell>Wait And Trade</TableCell>
              <TableCell>Move To Cost</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            style={{
              padding: '8px',
              // display: 'flex',
              // justifyContent: 'space-between',
            }}
          >
            {legs
              .sort((x: any, y: any) => x.id - y.id)
              .map((leg: any, index: number) => (
                <Leg
                  key={leg?.id}
                  data={leg}
                  overallData={legs}
                  removeLeg={() => removeLeg(leg?.id)}
                  updateLeg={updateLeg}
                />
              ))}
          </TableBody>
        </Table>
      </Box>
      <AddLeg addLeg={addLeg} />
    </>
  );
};

export { Legs, LegsList };

const AddLeg = (props: any) => {
  const onAddLeg = () => {
    props?.addLeg?.();
  };

  return (
    <Button
      block
      style={{ width: '100%', marginTop: '1em' }}
      type="dashed"
      // type="primary"
      icon={<PlusSquareOutlined />}
      // loading={loadings[1]}
      onClick={() => onAddLeg()}
    >
      Add Leg
    </Button>
  );
};

const StrikeBasedOnControl = ({ data, setData }: any) => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setData({ ...data, strikebasedon: value });
  };
  const options = [
    { value: 'fbsi', label: 'Future based strike index' },
    { value: 'prem', label: 'Premium' },
    { value: 'stdv', label: 'Standard deviation' },
    { value: 'opst', label: 'Option Strike' },
  ];

  return (
    <Form.Item
      name="tradebasedon"
      label="Trade Based On:"
    >
      <AntSelect
        defaultValue="fbsi"
        style={{ width: 220 }}
        onChange={handleChange}
        options={options}
      />
    </Form.Item>
  );
};
