import axios from 'axios';
import { URLS } from '../../constants';

export const getAllBrokers = async () => {
  const { data } = await axios.get(URLS.brokers.getAll);
  return data;
};

export const getAllInstruments = async () => {
  const { data } = await axios.get(URLS.brokers.getAllInstruments);
  return data;
};
