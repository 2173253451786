import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import Home from './page/Home';
// import Signup from './page/Signup';
// import Login from './page/Signin';
// import { Routes, Route, Outlet, Link } from 'react-router-dom';
// import { onAuthStateChanged } from 'firebase/auth';
// import { auth } from './firebase';
import // User,
// UserInfo,
'firebase/auth';
import { AuthProvider } from './AuthProvider';
import axiosIntercepter from './api/http';

axiosIntercepter();

export default function AppRouter() {
  // const [currentUser, setCurrentUser] = useState<UserInfo>();
  // const [timeActive, setTimeActive] = useState(false);

  // useEffect(() => {
  //   onAuthStateChanged(auth, (user: any) => {
  //     console.log('USer state changed!');
  //     setCurrentUser(user);
  //   });
  // }, []);

  const queryClient = new QueryClient();
  const queryDebugger = process.env.REACT_APP_DEBUGGER || false;

  return (
    <React.StrictMode>
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <App />
            {queryDebugger && <ReactQueryDevtools initialIsOpen={false} />}
          </AuthProvider>
        </QueryClientProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(<AppRouter />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
