import React from 'react'; // { useState, useRef, useMemo }
import {
  Col,
  Form,
  Row,
  Select,
  // Spin
} from 'antd';
import type { SelectProps } from 'antd/es/select';
// import debounce from 'lodash/debounce';

const InstrumentSearchAndSelect = (props: any) => {
  return (
    <Form
      labelCol={{ span: 6 }}
      layout="horizontal"
    >
      <Row>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 12 }}
        >
          <InstrumentTypeSelect {...props} />
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 12 }}
        >
          {/* <InstrumentSelect {...props} /> */}
          <InstrumentSelectStatic {...props} />
        </Col>
      </Row>
    </Form>
  );
};

const InstrumentTypeSelect = ({ data, setData }: any) => {
  // const [type, setType] = React.useState('fno');

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setData({ ...data, instrumentType: value });
  };
  const options = [
    { value: 'fno', label: 'F&O' },
    { value: 'cash', label: 'Equity' },
  ];

  return (
    <Form.Item
      name="instrumentType"
      label="Instrument Type :"
    >
      <Select
        defaultValue="fno"
        style={{ width: 220 }}
        onChange={handleChange}
        options={options}
      />
    </Form.Item>
  );
};

export {
  InstrumentSearchAndSelect,
  // InstrumentSelect,
  InstrumentTypeSelect,
  // InstrumentSelectStatic,
};

const InstrumentSelectStatic = ({ data, setData }: any) => {
  // const [type, setType] = React.useState('');

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setData({ ...data, instrumentType: value });
  };
  const options = [
    {
      value: 'BANKNIFTY',
      label: 'BANKNIFTY',
      exchange: 'NFO',
      symbol: 'BANKNIFTY',
      tradingsymbol: 'BANKNIFTY23JULFUT',
      type: 'FUT',
    },
    {
      value: 'FINNIFTY',
      label: 'FINNIFTY',
      exchange: 'NFO',
      symbol: 'FINNIFTY',
      tradingsymbol: 'FINNIFTY23JULFUT',
      type: 'FUT',
    },
    {
      value: 'NIFTY',
      label: 'NIFTY',
      exchange: 'NFO',
      symbol: 'NIFTY',
      tradingsymbol: 'NIFTY23JULFUT',
      type: 'FUT',
    },
  ];

  return (
    <Form.Item
      name="instrumentName"
      label="Instrument Name :"
    >
      <Select
        defaultValue="fno"
        style={{ width: 220 }}
        onChange={handleChange}
        options={options}
      />
    </Form.Item>
  );
};

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

// function DebounceSelect<
//   ValueType extends {
//     key?: string;
//     label: React.ReactNode;
//     value: string | number;
//   } = any,
// >({
//   fetchOptions,
//   debounceTimeout = 800,
//   ...props
// }: DebounceSelectProps<ValueType>) {
//   const [fetching, setFetching] = useState(false);
//   const [options, setOptions] = useState<ValueType[]>([]);
//   const fetchRef = useRef(0);

//   const debounceFetcher = useMemo(() => {
//     const loadOptions = (value: string) => {
//       fetchRef.current += 1;
//       const fetchId = fetchRef.current;
//       setOptions([]);
//       setFetching(true);

//       fetchOptions(value).then((newOptions) => {
//         if (fetchId !== fetchRef.current) {
//           // for fetch callback order
//           return;
//         }

//         setOptions(newOptions);
//         setFetching(false);
//       });
//     };

//     return debounce(loadOptions, debounceTimeout);
//   }, [fetchOptions, debounceTimeout]);

//   return (
//     <Select
//       labelInValue
//       filterOption={false}
//       onSearch={debounceFetcher}
//       notFoundContent={fetching ? <Spin size="small" /> : null}
//       {...props}
//       options={options}
//     />
//   );
// }

// Usage of DebounceSelect
// interface UserValue {
//   label: string;
//   value: string;
// }

// async function fetchUserList(username: string): Promise<UserValue[]> {
//   console.log('fetching user', username);

//   return fetch('https://randomuser.me/api/?results=5')
//     .then((response) => response.json())
//     .then((body) =>
//       body.results.map(
//         (user: {
//           name: { first: string; last: string };
//           login: { username: string };
//         }) => ({
//           label: `${user.name.first} ${user.name.last}`,
//           value: user.login.username,
//         }),
//       ),
//     );
// }

// const InstrumentSelect = ({ data, setData }: any) => {
//   const [value, setValue] = useState<UserValue[]>([]);

//   return (
//     <Form.Item
//       name="instrumentName"
//       label="Instrument Name :"
//     >
//       <DebounceSelect
//         mode="multiple"
//         value={value}
//         placeholder="Instrument Name"
//         fetchOptions={fetchUserList}
//         onChange={(newValue) => {
//           setValue(newValue as UserValue[]);
//         }}
//         style={{ width: '100%' }}
//       />
//     </Form.Item>
//   );
// };
