import React from 'react';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  TimePicker,
  Modal,
  Row,
  Col,
} from 'antd';
import { useState } from 'react';
import { Stack } from '@mui/material';
import { PlusSquareOutlined, DeleteTwoTone } from '@ant-design/icons';

const CONDITION_TEMPLATE = {
  field: '',
  value: '',
  operator: '',
  combinator: '',
  isInitialCondition: false,
};

const CONDITION_FIELDS = [
  { label: 'Trading Time', value: 'time' },
  { label: 'Trading Day', value: 'day' },
  { label: 'No. Of Days To Expiry', value: 'daystoexpiry' },
];

const OPERATORS: any = {
  time: [
    { label: 'Is Above', value: 'is_above' },
    { label: 'Is Below', value: 'is_below' },
    { label: 'Is', value: 'is' },
    { label: 'Is Between', value: 'is_between' },
  ],
  day: [
    { label: 'is', value: 'is' },
    { label: 'Any Of', value: 'any_of' },
    { label: 'Not Of', value: 'not_of' },
  ],
  daystoexpiry: [
    { label: 'isAbove', value: 'is_above' },
    { label: 'isBelow', value: 'is_below' },
    { label: 'is', value: 'is' },
  ],
};

export const ConditionBuilderModal = (props: any) => {
  const { open, onClose, saveConditions } = props;
  const [conditions, setConditions] = useState<any>([...props?.conditions]);
  const getTimeStamp = () => {
    return new Date().getTime();
  };
  const addCondition = () => {
    if (conditions?.length) {
      setConditions([
        ...conditions,
        { ...CONDITION_TEMPLATE, id: getTimeStamp(), combinator: 'and' },
      ]);
    } else {
      setConditions([
        { ...CONDITION_TEMPLATE, id: getTimeStamp(), isInitialCondition: true },
      ]);
    }
  };
  const removeCondition = (id: number) => {
    const newConditions = conditions.filter((i: any) => i.id !== id);
    if (newConditions.length === 1) {
      setConditions([{ ...newConditions[0], isInitialCondition: true }]);
    } else {
      setConditions(newConditions);
    }
  };

  const updateCondition = (condition: any) => {
    const newConditions = conditions.map((i: any) => {
      if (i.id === condition.id) {
        return condition;
      }
      return i;
    });
    setConditions(newConditions);
    console.log('updated condition data - ', newConditions);
  };

  const ruleProps = {
    removeCondition,
  };

  return (
    <>
      <Modal
        title="Condition Builder"
        centered
        open={open}
        onOk={() => saveConditions(conditions)}
        onCancel={() => onClose()}
        style={{ minWidth: '80%' }}
      >
        <Stack>
          {conditions.map((condition: any, index: number) => (
            <Rule
              key={condition?.id}
              {...ruleProps}
              condition={condition}
              updateData={updateCondition}
              removeCondition={removeCondition}
            />
          ))}
          <Button
            block
            style={{ width: '100%' }}
            type="dashed"
            // type="primary"
            icon={<PlusSquareOutlined />}
            // loading={loadings[1]}
            onClick={() => {
              addCondition();
            }}
          >
            Add Condition
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

const Rule = ({ condition, updateData, removeCondition }: any) => {
  const {
    // value,
    // field,
    // operator,
    combinator,
    isInitialCondition,
    id,
  } = condition;
  const { Option } = Select;
  const [selectedField, setSelectedField] = useState<any>('');
  // const [inputValue, setInputValue] = useState<any>('');
  const handleFieldChange = (value: string) => {
    console.log(`selected ${value}`);
    setSelectedField(value);
  };
  const [checked, setChecked] = useState(combinator === 'or' ? false : true);
  const updateRule = (e: Boolean) => {
    // data.optionType = e ? 'ce' : 'pe';
    setChecked(e ? true : false);
    // updateLeg?.(data);
  };

  const onInputValueChange = (e: any) => {
    if (selectedField === 'time') {
      const time = e.format('HH:mm:ss');
      console.log('time - ', time);
      updateData({ ...condition, value: time });
    } else if (selectedField === 'day') {
      updateData({ ...condition, value: e });
    } else if (selectedField === 'daystoexpiry') {
      updateData({ ...condition, value: e });
    } else {
      updateData({ ...condition, value: e });
    }
    // console.log('input value changed', e.target.value);
    // setInputValue(e.target.value);
  };

  // const onFinish = (values: any) => {
  //   console.log('Received values of form: ', values);
  // };
  return (
    <Form
      name="conditions"
      // onFinish={onFinish}
      // labelCol={{ span: 8 }}
      // wrapperCol={{ span: 16 }}
      // style={{ maxWidth: 600 }}
    >
      <>
        {!!id && (
          <Row>
            <Col
              xs={{ span: 23, offset: 1 }}
              lg={{ span: 2, offset: 1 }}
            >
              <Form.Item
                rules={[{ required: true, message: 'Field is required' }]}
                // style={{ marginRight: '1em', width: '58px' }}
              >
                {isInitialCondition ? (
                  <div></div>
                ) : (
                  <Switch
                    style={{ backgroundColor: checked ? '#1677ff' : 'gold' }}
                    checkedChildren="AND"
                    unCheckedChildren="OR"
                    defaultChecked={combinator === 'and'}
                    onChange={(e) => {
                      updateRule(e);
                      updateData({
                        ...condition,
                        combinator: e ? 'and' : 'or',
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>

            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 1 }}
            >
              <Form.Item
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Select
                  // style={{ width: '200px' }}
                  // onChange={handleFieldChange}
                  onChange={(e) => {
                    handleFieldChange(e);
                    updateData({ ...condition, field: e });
                  }}
                  // size="small"
                >
                  {CONDITION_FIELDS.map((field: any) => (
                    <Option
                      key={field.label}
                      value={field.value}
                    >
                      {field.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 1 }}
            >
              <Form.Item
                rules={[{ required: true, message: 'Field is required' }]}
              >
                <Select
                  // style={{ width: '100px' }}
                  // size="small"
                  onChange={(e) => {
                    // handleFieldChange(e);
                    updateData({ ...condition, operator: e });
                  }}
                >
                  {!!selectedField
                    ? OPERATORS[selectedField].map((field: any) => (
                        <Option
                          key={field.label}
                          value={field.value}
                        >
                          {field.label}
                        </Option>
                      ))
                    : null}
                </Select>
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24, offset: 0 }}
              lg={{ span: 5, offset: 1 }}
            >
              <Form.Item
                name="value"
                // noStyle
                rules={[{ required: true, message: 'Street is required' }]}
              >
                <ValueInput
                  // style={{ width: '200px' }}
                  // size="small"
                  type={selectedField}
                  onChange={(e: any) => {
                    onInputValueChange(e);
                  }}
                />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              lg={{ span: 2 }}
            >
              <Form.Item
                label=" "
                colon={false}
              >
                <Button
                  title="Delete Condition"
                  icon={<DeleteTwoTone twoToneColor="#ff0000" />}
                  size="small"
                  onClick={() => {
                    removeCondition(condition?.id);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </>
    </Form>
  );
};

const ValueInput = (props: any) => {
  const { type, onChange } = props;
  if (type === 'time') {
    return (
      <TimePicker
        {...props}
        // style={{ ...props?.style }}
        onChange={onChange}
      />
    );
  } else if (type === 'day') {
    return (
      <DaysSelectInput
        {...props}
        onChange={onChange}
      />
    );
  } else if (type === 'daystoexpiry') {
    return (
      <InputNumber
        {...props}
        min={1}
        max={10}
        onChange={onChange}
      />
    );
  } else {
    return (
      <Input
        {...props}
        onChange={onChange}
      />
    );
  }
};

const DAYS = [
  { value: 'Monday', label: 'Monday' },
  { value: 'Tuesday', label: 'Tuesday' },
  { value: 'Wednesday', label: 'Wednesday' },
  { value: 'Thursday', label: 'Thursday' },
  { value: 'Friday', label: 'Friday' },
];

const DaysSelectInput = (props: any) => {
  const { onChange } = props;
  const [selectedItems, setSelectedItems] = useState<string[]>([]);

  const filteredOptions = DAYS.filter((o) => !selectedItems.includes(o?.value));

  return (
    <Select
      style={{ ...props.style, minWidth: '150px' }}
      mode="multiple"
      value={selectedItems}
      onChange={(val: any) => {
        console.log('selected items', val);
        setSelectedItems(val);
        onChange(val);
      }}
      options={filteredOptions}
    />
  );
};
