import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
// import { useAuth } from '../AuthProvider';
import { useMutation } from 'react-query';
import {
  // getUserProfileData,
  // updateUserProfileData,
  // getTradingAccountsForUser,
  // updateTradingAccountForUser,
  processRequestToken,
} from '../api/routes/user';
// import AddBrokerDialog from '../components/addBroker';

const BrokerAuthSuccess = () => {
  const [isCalled, setIsCalled] = useState(false);

  const { mutate: postRequestToken } = useMutation(processRequestToken, {
    onSuccess: (res) => {
      const { data } = res;
      console.log('onSuccess data', data);
      if (!!data?.brokerUserAccessToken) {
        console.log(data);
        // window.location.href = '/profile';
      }
    },
    onError: (error) => {
      console.log(error);
      // window.location.href = '/profile';
    },
    onSettled: () => {
      console.log('onSettled');
      window.location.href = '/profile';
    },
  });

  const [searchParams] = useSearchParams();
  console.log(searchParams.get('code')); // 'name'
  const path = useParams();
  const urlsParts = path['*']?.split('/');
  const brokerId = urlsParts?.[0];
  const authId = urlsParts?.[1];

  if (brokerId === 'fltr') {
    const requestCode = searchParams.get('code');
    const clientId = searchParams.get('client');
    console.log(clientId);
    const payload = {
      requestToken: requestCode,
      authId,
      brokerId,
    };
    if (!isCalled) {
      postRequestToken(payload);
      setIsCalled(true);
    }
  } else if (brokerId === 'zrdh') {
    const requestToken = searchParams.get('request_token');
    const clientId = searchParams.get('client');
    console.log(clientId);
    const payload = {
      requestToken: requestToken,
      authId,
      brokerId,
    };
    if (!isCalled) {
      postRequestToken(payload);
      setIsCalled(true);
    }
  }

  return (
    <>
      <Typography
        variant="button"
        display="block"
        gutterBottom
      >
        Auth success page
      </Typography>

      <Typography
        variant="button"
        display="block"
        gutterBottom
      >
        Broker ID : {brokerId}
      </Typography>

      <Typography
        variant="button"
        display="block"
        gutterBottom
      >
        Request code : {searchParams.get('code')}
      </Typography>

      <Typography
        variant="button"
        display="block"
        gutterBottom
      >
        Client ID : {searchParams.get('client')}
      </Typography>
    </>
  );
};

export default BrokerAuthSuccess;
