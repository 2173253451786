import React, { useState } from 'react';
import {
  Button,
  ListItem,
  ListItemText,
  Typography,
  List,
  TextField,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  IconButton,
  Divider,
} from '@mui/material';
import { FaTrash } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getUserProfileData,
  updateUserProfileData,
  getTradingAccountsForUser,
  updateTradingAccountForUser,
  deleteTradingAccountForUser,
} from '../api/routes/user';
import AddBrokerDialog from '../components/addBroker';

const Profile = () => {
  const auth = useAuth();
  const { data } = useQuery('getuserprofile', getUserProfileData, {
    refetchOnWindowFocus: false,
    enabled: auth?.user?.emailVerified,
  });
  return (
    <>
      <UserDetails userData={data} />
      <TradingAccountsList userData={data} />
    </>
  );
};

export default Profile;

const UserDetails = ({ userData }: any) => {
  const queryClient = useQueryClient();
  const [profileEdit, setProfileEdit] = useState(false);

  const { mutate: doProfileUpdate } = useMutation(updateUserProfileData, {
    onSuccess: (res) => {
      queryClient.invalidateQueries('getuserprofile');
    },
  });

  const updateProfileAction = (e: any) => {
    e.preventDefault();
    console.log(e);
    const {
      userName: { value: userName },
      phone: { value: phone },
    } = e?.currentTarget?.elements;
    doProfileUpdate({ userName, phone });
    setProfileEdit(false);
  };
  return (
    <>
      {profileEdit ? (
        <form onSubmit={updateProfileAction}>
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
              >
                Update your Profile
              </Typography>
              <TextField
                fullWidth
                name="userName"
                id="full-name"
                label="Full name"
                variant="standard"
                defaultValue={userData?.userName}
              />

              <TextField
                fullWidth
                name="phone"
                id="phone"
                label="Phone"
                variant="standard"
                type="tel"
                defaultValue={userData?.phone}
              />

              {/* <TextField
              name="email"
              id="email"
              label="Email"
              variant="standard"
            /> */}
            </CardContent>
            <CardActions style={{ float: 'right' }}>
              <Button
                onClick={() => {
                  setProfileEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </CardActions>
          </Card>
        </form>
      ) : (
        <>
          <Card>
            <CardHeader
              avatar={<></>}
              action={
                <Button onClick={() => setProfileEdit(true)}>Edit</Button>
              }
              title="Your Profile"
              // subheader="September 14, 2016"
            />
            <CardContent>
              <List>
                <ListItem>
                  <ListItemText
                    primary={'User Name'}
                    secondary={userData?.userName || '-'}
                  ></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={'Email'}
                    secondary={userData?.email}
                  ></ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={'Phone'}
                    secondary={userData?.phone || '-'}
                  ></ListItemText>
                </ListItem>
              </List>
            </CardContent>
            {/* <CardActions style={{ float: 'right' }}>
              <Button onClick={() => setProfileEdit(true)}>Edit</Button>
            </CardActions> */}
          </Card>
        </>
      )}
    </>
  );
};

const TradingAccountsList = ({ userData }: any) => {
  const auth = useAuth();
  const queryClient = useQueryClient();
  const [brokerEdit, setBrokerEdit] = useState(false);
  const { data = [] } = useQuery(
    'getUserTradingAccounts',
    getTradingAccountsForUser,
    {
      refetchOnWindowFocus: false,
      enabled: auth?.user?.emailVerified,
    },
  );

  const { mutate: doBrokerAccountUpdate } = useMutation(
    updateTradingAccountForUser,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('getUserTradingAccounts');
      },
    },
  );

  const updateProfileAction = (e: any) => {
    e.preventDefault();
    console.log(e);
    const {
      userName: { value: userName },
      phone: { value: phone },
    } = e?.currentTarget?.elements;
    doBrokerAccountUpdate({ userName, phone });
    setBrokerEdit(false);
  };
  return (
    <div style={{ marginTop: '1em', marginBottom: '1em' }}>
      {brokerEdit ? (
        <form onSubmit={updateProfileAction}>
          <Card>
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
              >
                Edit broker accounts
              </Typography>
            </CardContent>
            <CardActions style={{ float: 'right' }}>
              <Button
                onClick={() => {
                  setBrokerEdit(false);
                }}
              >
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </CardActions>
          </Card>
        </form>
      ) : (
        <>
          <Card>
            <CardHeader
              avatar={<></>}
              action={<AddBrokerDialog userData={userData} />}
              title="Trading accounts"
              // subheader="September 14, 2016"
            />

            <CardContent>
              <List>
                {[...data]?.map((i: any) => (
                  <BrokerListItem
                    key={data?.id}
                    data={i}
                  />
                ))}
              </List>
            </CardContent>
            {/* <CardActions style={{ float: 'right' }}>
              <Button onClick={() => setBrokerEdit(true)}>Edit</Button>
            </CardActions> */}
          </Card>
        </>
      )}
    </div>
  );
};

const BrokerListItem = ({ data }: any) => {
  const queryClient = useQueryClient();
  const { mutate: deleteAccount } = useMutation(deleteTradingAccountForUser, {
    onSuccess: (res) => {
      // invalidate query to trigger refetch
      queryClient.invalidateQueries('getUserTradingAccounts');
      console.log('deleted', res);
    },
  });

  const getBrokerLoginUrl = (data: any) => {
    // get the broker login url and replace the text {API_KEY} with brokerUserApiKey
    const brokerLoginUrl = data?.broker?.brokerLoginUrl;
    const brokerUserApiKey = data?.brokerUserApiKey;
    return brokerLoginUrl?.replace('{APIKEY}', brokerUserApiKey);
  };

  return (
    <>
      <Divider
        variant="inset"
        component="li"
      />

      <ListItem
        secondaryAction={
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => {
              deleteAccount(data?.id);
              console.log('delete', data?.brokerId, data?.id);
            }}
          >
            <FaTrash />
          </IconButton>
        }
      >
        <ListItemText
          primary={data?.broker.brokerName}
          secondary={data?.brokerUserId || '-'}
        />
        <NavLink
          to={getBrokerLoginUrl(data)}
          target="_self"
          rel="noopener noreferrer"
        >
          Login
        </NavLink>
      </ListItem>
    </>
  );
};
