// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBoRYs_2EigPSptYB06i_zVs2CGtX1nOy0',
  authDomain: 'marketsage-66754.firebaseapp.com',
  projectId: 'marketsage-66754',
  storageBucket: 'marketsage-66754.appspot.com',
  messagingSenderId: '509434422325',
  appId: '1:509434422325:web:aed9183c15cc6e52507d7c',
  measurementId: 'G-75VSXCTHBN',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
console.log(analytics);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// setPersistence(auth, browserLocalPersistence);

export default app;
