import * as React from 'react';
import {
  Routes,
  Route,
  // Link,
  // useNavigate,
  // useLocation,
  // Navigate,
  Outlet,
} from 'react-router-dom';
// import {
//   signInWithEmailAndPassword,
//   onAuthStateChanged,
//   signOut,
// } from 'firebase/auth';
import { useQuery } from 'react-query';
// import { auth } from './firebase';
import {
  //  AuthProvider,
  useAuth,
} from './AuthProvider';
import Signin from './page/Signin';
import Header from './components/Header';
import Signup from './page/Signup';
import Home from './page/Home';
import Profile from './page/Profile';
import RequireAuth from './RequireAuth';
import { getTestData } from './api/routes/dashboard';
import { Container } from '@mui/material';
import BrokerAuthSuccess from './page/BrokerLoginSuccess';
import CreateStrategy from './page/CreateStrategy';
import StrategyList from './page/StrategyList';
import './App.css';
// import StrategyDetails from './page/StrategyDetails';
import Strategy from './page/StrategyDetails';

export default function App() {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={<Home />}
          />
          <Route
            path="/signin"
            element={<Signin />}
          />
          <Route
            path="/signup"
            element={<Signup />}
          />
          <Route
            path="/profile"
            element={
              <RequireAuth>
                <Profile />
              </RequireAuth>
            }
          />
          <Route
            path="/create-strategy"
            element={
              <RequireAuth>
                <CreateStrategy />
              </RequireAuth>
            }
          />
          <Route
            path="/strategies"
            element={
              <RequireAuth>
                <StrategyList />
              </RequireAuth>
            }
          />
          <Route
            path="/strategies/:strategyId/*"
            element={
              <RequireAuth>
                <Strategy />
              </RequireAuth>
            }
          />
          <Route
            path="/auth/*"
            element={
              <RequireAuth>
                <BrokerAuthSuccess />
              </RequireAuth>
            }
          ></Route>
        </Route>
      </Routes>
    </>
  );
}

function Layout() {
  const auth = useAuth();

  const { data } = useQuery('test', getTestData, {
    refetchOnWindowFocus: false,
    enabled: auth?.user?.emailVerified,
  });
  console.log(data);

  return (
    <div>
      <Header />
      <Container
        maxWidth="lg"
        style={{ paddingTop: '2em' }}
      >
        <Outlet />
      </Container>
    </div>
  );
}
