import React, { useState } from 'react';
import { Card, Button } from 'antd';
import { PlusSquareOutlined, EditFilled } from '@ant-design/icons';
import { ConditionBuilderModal } from './conditionBuilder';
import ConditionPreview from './conditionPreview';

const EntryCondition = (props: any) => {
  const { data, setData } = props;
  const [showConditionBuilder, setShowConditionBuilder] = useState<any>(false);
  const onConditionBuilderClose = () => {
    setShowConditionBuilder(false);
  };
  const updateEntryConditions = (items: any) => {
    console.log('Entry conditions list -', items);
    const newData = { ...data, entryConditions: [...items] };
    setData(newData);
    onConditionBuilderClose();
  };

  return (
    <>
      <Card
        style={props?.style}
        size="small"
        title="Entry Condition"
        extra={
          <Button
            block
            style={{ width: '100%' }}
            type="dashed"
            // type="primary"
            icon={
              data?.entryConditions?.length ? (
                <EditFilled />
              ) : (
                <PlusSquareOutlined />
              )
            }
            // loading={loadings[1]}
            onClick={() => {
              setShowConditionBuilder(true);
            }}
          >
            {data?.entryConditions?.length
              ? 'Edit Conditions'
              : 'Build Conditions'}
          </Button>
        }
      >
        {data?.entryConditions?.map((condition: any) => (
          <ConditionPreview condition={condition} />
        ))}
      </Card>
      <ConditionBuilderModal
        open={showConditionBuilder}
        conditions={props?.entryConditions || []}
        saveConditions={updateEntryConditions}
        onClose={onConditionBuilderClose}
      />
    </>
  );
};

export default EntryCondition;
