import axios from 'axios';
// import config from './config';
import { auth } from '../firebase';
import { getIdToken } from 'firebase/auth';

// export const axiosInstance = axios.create({
//   baseURL: config.api.baseURL,
// });
export default function axiosIntercepter() {
  axios.interceptors.request.use(
    async (req) => {
      // Do something before request is sent

      const token = await getIdToken?.(auth.currentUser);
      if (token) {
        req.headers.Authorization = 'Bearer ' + token;
        return req;
      }
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      return Promise.reject(error);
    },
  );
}
