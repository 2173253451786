import React from 'react';
import { Tag } from 'antd';
// import { PlusSquareOutlined } from '@ant-design/icons';
// import { ConditionBuilderModal } from './conditionBuilder';

const ConditionPreview = ({ condition }: any) => {
  return (
    <>
      {condition?.combinator && (
        <Tag
          color="gold"
          style={{ textTransform: 'uppercase', borderStyle: 'dashed' }}
        >
          {condition?.combinator}
        </Tag>
      )}
      <Tag style={{ padding: '2px' }}>
        <Tag
          color="magenta"
          style={{ textTransform: 'capitalize' }}
        >
          {condition?.field}
        </Tag>
        {/* <Tag color="green"> */}
        <Tag style={{ textTransform: 'capitalize' }}>
          {condition?.operator.replace('_', ' ')}
        </Tag>
        {/* </Tag> */}
        <Tag
          color="blue"
          style={{ margin: '0', textTransform: 'capitalize' }}
        >
          {condition?.value?.join
            ? condition?.value?.join?.(', ')
            : condition?.value}
          {/* {typeof(condition?.value) === 'array' ? condition?.value?.join(', ') : condition?.value'} */}
        </Tag>
      </Tag>
    </>
  );
};

export default ConditionPreview;
