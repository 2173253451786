import React from 'react';
import {
  useLocation,
  // Navigate,
  NavLink,
} from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Signin from './page/Signin';
// import { auth } from './firebase';

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (auth?.user?.email && auth?.user?.emailVerified) {
    // Redirect them to the /signin page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return children;
  } else if (auth?.user?.email && !auth?.user?.emailVerified) {
    return (
      <>
        <p>
          {' '}
          An verification email has been sent to{' '}
          <NavLink to="">{auth?.user?.email}</NavLink>
        </p>
        <div>You need to verify your email before proceeding.</div>
      </>
    );
  } else {
    return (
      // <Navigate
      //   to="/signin"
      //   state={{ from: location }}
      //   replace
      // />
      <>
        <div>You need to login to see this content.</div>
        <Signin navOnSuccess={location} />
      </>
    );
  }
}

export default RequireAuth;
