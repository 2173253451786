// Create a react component called StrategyList that will be used to list all the strategies, refer the website "quantman.in"
import React from 'react';
import { useParams } from 'react-router-dom';
// import { useAuth } from '../AuthProvider';
// import { useQueryClient } from 'react-query';
import { getStrategyById } from '../api/routes/strategy';
import { useQuery } from 'react-query';
// import components from @mui/material
import { Card, Dropdown, Tabs, Tag } from 'antd';
// import react icons
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { TbReport, TbFileInfo } from 'react-icons/tb';

import type { MenuProps } from 'antd';

// const PaperStyles = { mt: 2, pt: 2 };

const Strategy = () => {
  const { strategyId = '' } = useParams();
  // const navigate = useNavigate();
  // const auth = useAuth();
  // const queryClient = useQueryClient();
  const {
    data,
    // isLoading, error
  } = useQuery(['getStrategyById', strategyId], getStrategyById, {
    onSuccess: (res) => {
      console.log(res);
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log('onSettled');
    },
  });

  const onMenuClick: MenuProps['onClick'] = (e: any) => {
    console.log('click', e);
  };

  return (
    <>
      <Card
        title={data?.strategyName}
        style={{ width: '100%' }}
        extra={[
          <Dropdown.Button
            type="primary"
            menu={{
              items: [
                {
                  key: '1',
                  label: 'Run Backtest',
                },
                {
                  key: '2',
                  label: 'Run Paper Trade',
                },
                {
                  key: '3',
                  label: 'Clone Strategy',
                },
                {
                  key: '4',
                  label: <span style={{ color: 'red' }}>Delete Strategy</span>,
                },
              ],
              onClick: onMenuClick,
            }}
          >
            Deploy Live
          </Dropdown.Button>,
        ]}
      >
        <div>
          <StrategyTabs />
        </div>
      </Card>
    </>
  );
};

export default Strategy;

const StrategyTabs = () => {
  const TABS = [
    {
      label: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <TbReport style={{ marginRight: '4px' }} />
          Live Trade
        </span>
      ),
      key: '1',
      children: <LiveTrade />,
    },
    {
      label: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <TbReport style={{ marginRight: '4px' }} />
          Backtest Report
        </span>
      ),
      key: '2',
      children: <BackTestReport />,
    },
    {
      label: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <TbReport style={{ marginRight: '4px' }} />
          Paper Trade
        </span>
      ),
      key: '3',
      children: <PaperTrade />,
    },
    {
      label: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <TbFileInfo style={{ marginRight: '4px' }} />
          Details
        </span>
      ),
      key: '4',
      children: <StrategyDetails />,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
  };

  return (
    <Tabs
      defaultActiveKey="1"
      onChange={onChange}
      type="card"
      items={TABS}
    />
  );
};

const BackTestReport = () => {
  return <div>Backtest</div>;
};

const LiveTrade = () => {
  return (
    <div style={{ paddingTop: '1em' }}>
      <Tag
        icon={<ExclamationCircleOutlined />}
        color="gold"
        style={{ textAlign: 'center' }}
      >
        Currently not deployed to Live
      </Tag>
    </div>
  );
};

const PaperTrade = () => {
  return <div>Paper Trading report</div>;
};

const StrategyDetails = () => {
  return <div>Details</div>;
};
