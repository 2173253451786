import React, { useEffect, useState } from 'react';
import { Box, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { FaPlus } from 'react-icons/fa';
// import Grid from '@mui/material/Grid/Grid';
import { BrokerSelect } from './brokerSelect';
// import { useDeviceLanguage } from 'firebase/auth';
import { createTradingAccountForUser } from '../../api/routes/user';
import { useMutation, useQueryClient } from 'react-query';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle
      sx={{ m: 0, p: 2 }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function AddBrokerDialog({ userData }: any) {
  const queryClient = useQueryClient();
  console.log('user data is ', userData);
  const [open, setOpen] = React.useState(false);
  const [selectedBroker, setSelectedBroker] = useState<any>({});

  const handleClickOpen = () => {
    setSelectedBroker({});
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { mutate: createTradingAccount } = useMutation(
    createTradingAccountForUser,
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries('getUserTradingAccounts');
        handleClose();
        console.log('success');
      },
      onError: (err) => {
        console.log('error');
      },
      onSettled: (data, error) => {
        console.log('data is ', data);
        console.log('error is ', error);
      },
    },
  );
  const submitAccountDetails = (e: any) => {
    e.preventDefault();
    console.log(e);
    const formData = new FormData(e?.currentTarget);
    const formDataEntries = formData?.entries();
    const { brokerId } = selectedBroker;
    if (!!brokerId && formDataEntries) {
      const payload: any = { brokerId };
      for (const [key, value] of formDataEntries) {
        console.log(key, value);
        payload[key] = value;
      }
      console.log(payload);
      createTradingAccount(payload);
    }
    // const {
    //   userName: { value: userName },
    //   phone: { value: phone },
    // } = e?.currentTarget?.elements;
    // doProfileUpdate({ userName, phone });
    // setProfileEdit(false);
  };

  useEffect(() => {
    return () => {
      setSelectedBroker({});
    };
  }, []);

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        size="small"
        variant="outlined"
        startIcon={<FaPlus />}
      >
        Add Broker
      </Button>
      <BootstrapDialog
        maxWidth="xl"
        onClose={handleClose}
        aria-labelledby="add-broker-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="add-broker-dialog-title"
          onClose={handleClose}
        >
          Select Broker
        </BootstrapDialogTitle>
        <DialogContent
          dividers
          sx={{ minWidth: '268px' }}
        >
          <BrokerSelect onSelect={(broker: any) => setSelectedBroker(broker)} />
          <Box>
            {selectedBroker?.registrationFields?.length > 0 ? (
              <form onSubmit={submitAccountDetails}>
                {selectedBroker?.registrationFields?.map(
                  (field: any, index: number) => (
                    <TextField
                      style={{
                        marginTop: '1em',
                        marginLeft:
                          index > 0 ||
                          index + 1 ===
                            selectedBroker?.registrationFields?.length
                            ? '1em'
                            : '0',
                      }}
                      // fullWidth
                      name={field?.mapToCol}
                      id={field?.fieldId}
                      key={field?.fieldId}
                      label={field?.fieldLabel}
                      variant="outlined"
                      // defaultValue={field?.fieldDefaultValue}
                    />
                  ),
                )}

                {selectedBroker &&
                !!selectedBroker?.brokerId &&
                userData &&
                !!userData?.authId ? (
                  <Typography style={{ marginTop: '1em', marginBottom: '1em' }}>
                    {`https://localhost:3000/auth/${selectedBroker?.brokerId}/${userData?.authId}`}
                  </Typography>
                ) : null}

                <Button
                  style={{ float: 'right' }}
                  autoFocus
                  // onClick={handleClose}
                  type="submit"
                >
                  Save
                </Button>
              </form>
            ) : null}
          </Box>
        </DialogContent>
        {/* <DialogActions>
          <Button
            autoFocus
            // onClick={handleClose}
            type="submit"
          >
            Save
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
