import React, { useState } from 'react';
import {
  NavLink,
  // useNavigate
} from 'react-router-dom';
import {
  createUserWithEmailAndPassword,
  signOut,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from '../firebase';
// import { useAuth } from '../AuthProvider';
import TextField from '@mui/material/TextField/TextField';
import Button from '@mui/material/Button';

const Signup = () => {
  // const navigate = useNavigate();
  // const auth = useAuth();
  const [showEmailVerify, setShowEmailVerify] = useState(false);
  const [userEmail, setUserEmail] = useState<string>('test@test.com');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = async (e: any) => {
    e.preventDefault();

    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential?.user;
        console.log(user);
        // navigate('/signin');

        if (user) {
          const { email } = user;
          await setUserEmail(!!email ? email : '');
          sendEmailVerification(user);
          signOut(auth).then(() => {
            setShowEmailVerify(true);
          });
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });
  };

  return (
    <main>
      <section>
        <div
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '2em',
          }}
        >
          {showEmailVerify ? (
            <div
              style={{
                width: '300px',
                height: '480px',
              }}
            >
              <p>Account created successfully!</p>
              <p>
                {' '}
                An verification email has been sent to{' '}
                <NavLink to="">{userEmail}</NavLink>
              </p>
              <p>Please verify the email before logging in.</p>
              <div />
              <p>
                Already verified your email?{' '}
                <NavLink to="/signin">Sign in</NavLink>
              </p>
            </div>
          ) : (
            <div
              style={{
                width: '300px',
                height: '400px',
              }}
            >
              <p style={{ textAlign: 'center' }}> Market sage </p>
              <form>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  variant="standard"
                  id="email-address"
                  label="Email"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  label="Password"
                  variant="standard"
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  fullWidth
                  sx={{ mb: 2 }}
                  onClick={onSubmit}
                >
                  Sign up
                </Button>
              </form>

              <p>
                Already have an account? <NavLink to="/signin">Sign in</NavLink>
              </p>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default Signup;
