import React, { useState } from 'react';
import {
  // Input as AntInput,
  Select as AntSelect,
  Space,
  Switch,
  InputNumber,
  Button,
} from 'antd';
import {
  // Table,
  // TableBody,
  TableRow,
  TableCell,
  // Box
} from '@mui/material';
import {
  DeleteOutlined,
  UpOutlined,
  DownOutlined,
  PercentageOutlined,
} from '@ant-design/icons';

import { Checkbox } from 'antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';

// import { SettingOutlined } from '@ant-design/icons';
import {
  // Cascader,
  Input,
  //  Select
} from 'antd';

const Leg = ({ data, overallData, removeLeg, updateLeg }: any) => {
  const {
    transactionType,
    // optionType, strike, actionType, lots
  } = data;
  return (
    <TableRow
      style={{
        padding: '8px',
        // display: 'flex',
        // justifyContent: 'space-between',
      }}
    >
      <TableCell>
        <TransactionTypeToggle
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>
      <TableCell>
        {transactionType === 'option' && (
          <OptionTypeControl
            updateLeg={updateLeg}
            data={data}
          />
        )}
      </TableCell>
      <TableCell>
        {transactionType === 'option' && (
          <StrikeSelect
            updateLeg={updateLeg}
            data={data}
          />
        )}
      </TableCell>
      <TableCell>
        <ExpiryCycleControl
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>
      <TableCell>
        <ActionTypeControl
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>
      <TableCell>
        <LotsInput
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>
      <TableCell>
        <StopLossControl
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>

      <TableCell>
        <TakeProfitControl
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>

      <TableCell>
        <TrailingStopLossControl
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>

      <TableCell>
        <ReEntryControl
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>

      <TableCell>
        <WaitAndTradeControl
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>
      <TableCell>
        <MoveToCost
          updateLeg={updateLeg}
          data={data}
        />
      </TableCell>
      <TableCell>
        <RemoveLeg
          removeLeg={removeLeg}
          overallData={overallData}
        />
      </TableCell>
    </TableRow>
  );
};

export { Leg };

const RemoveLeg = (props: any) => {
  const onRemoveLeg = () => {
    props?.removeLeg?.();
  };

  return (
    <Space wrap>
      <Button
        disabled={props?.overallData?.length < 2}
        type="primary"
        icon={<DeleteOutlined />}
        // loading={loadings[1]}
        onClick={() => onRemoveLeg()}
      ></Button>
    </Space>
  );
};

const StopLossControl = ({ updateLeg, data }: any) => {
  const { Option } = AntSelect;

  const selectBefore = (
    <AntSelect
      defaultValue="%"
      style={{ minWidth: '56px' }}
    >
      <Option value="percent">%</Option>
      <Option value="rupees">₹</Option>
      <Option value="points">Pts.</Option>
    </AntSelect>
  );
  return (
    <InputNumber
      style={{ width: '162px' }}
      min="0"
      addonAfter={selectBefore}
      defaultValue="0"
    />
  );
};

const TakeProfitControl = ({ updateLeg, data }: any) => {
  const { Option } = AntSelect;

  const selectBefore = (
    <AntSelect
      defaultValue="%"
      style={{ minWidth: '56px' }}
    >
      <Option value="percent">%</Option>
      <Option value="rupees">₹</Option>
      <Option value="points">Pts.</Option>
    </AntSelect>
  );
  return (
    <InputNumber
      style={{ width: '162px' }}
      min="0"
      addonAfter={selectBefore}
      defaultValue="0"
    />
  );
};

const ActionTypeControl = ({ updateLeg, data }: any) => {
  const [checked, setChecked] = useState(true);
  return (
    <Switch
      style={{ backgroundColor: checked ? 'limegreen' : 'red' }}
      checked={checked}
      checkedChildren="BUY"
      unCheckedChildren="SELL"
      onChange={(e) => setChecked(e)}
      defaultChecked
    />
  );
};

const OptionTypeControl = ({ updateLeg, data }: any) => {
  console.log(data?.optionType);
  const type = data?.optionType;
  const [checked, setChecked] = useState(type === 'ce' ? true : false);
  const updateData = (e: Boolean) => {
    data.optionType = e ? 'ce' : 'pe';
    setChecked(e ? true : false);
    updateLeg?.(data);
  };
  return (
    <Switch
      style={{ backgroundColor: checked ? '#1677ff' : 'gold' }}
      checked={checked}
      checkedChildren="CE"
      unCheckedChildren="PE"
      onChange={(e) => updateData(e)}
      defaultChecked
    />
  );
};

const TransactionTypeToggle = ({ updateLeg, data }: any) => {
  console.log(data?.transactionType);
  const type = data?.transactionType;
  const [checked, setChecked] = useState(type === 'option' ? true : false);
  const updateData = (e: Boolean) => {
    data.transactionType = e ? 'option' : 'future';
    setChecked(e ? true : false);
    updateLeg?.(data);
  };
  return (
    <Switch
      style={{ backgroundColor: '#1677ff' }}
      checked={checked}
      checkedChildren="OPTION"
      unCheckedChildren="FUTURE"
      onChange={(e) => updateData(e)}
      defaultChecked
    />
  );
};

const LotsInput = ({ data, updateLeg }: any) => {
  const onChange = (value: any) => {
    console.log('changed', value);
    data.lots = value;
    updateLeg?.(data);
  };
  return (
    <InputNumber
      onChange={(e) => onChange(e)}
      min={1}
      max={100}
      defaultValue={0}
    />
  );
};

const StrikeSelect = ({ updateLeg, data }: any) => {
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };
  const itms = [...Array(30).keys()].reverse().map((i) => {
    return { value: `${-(i + 1)}`, label: `ITM${i + 1}` };
  });
  const atm = { value: '0', label: 'ATM' };
  const otms = [...Array(30).keys()].map((i) => {
    return { value: `${i + 1}`, label: `OTM${i + 1}` };
  });
  const options = [...itms, atm, ...otms];

  return (
    <AntSelect
      defaultValue="0"
      style={{ width: 120 }}
      onChange={handleChange}
      options={options}
    />
  );
};

const MoveToCost = ({ updateLeg, data }: any) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
  };
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Checkbox
        onChange={onChange}
        checked={checked}
      ></Checkbox>
    </div>
  );
};

const ReEntryControl = ({ updateLeg, data }: any) => {
  const { Option } = AntSelect;
  const [checked, setChecked] = useState(false);
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
  };

  const selectBefore = (
    <AntSelect
      disabled={!checked}
      defaultValue="reentry"
      style={{ minWidth: '56px' }}
    >
      <Option value="reentry">ReEntry</Option>
      <Option value="reexecute">ReExecute</Option>
    </AntSelect>
  );
  return (
    <Space>
      <Checkbox
        onChange={onChange}
        checked={checked}
      ></Checkbox>
      <InputNumber
        disabled={!checked}
        style={{ width: '162px' }}
        min="0"
        addonBefore={selectBefore}
        defaultValue="0"
      />
    </Space>
  );
};

const WaitAndTradeControl = ({ updateLeg, data }: any) => {
  const { Option } = AntSelect;
  const [checked, setChecked] = useState(false);
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
  };

  const selectBefore = (
    <AntSelect
      disabled={!checked}
      defaultValue="up"
      style={{ minWidth: '56px' }}
    >
      <Option value="up">
        <UpOutlined style={{ color: '#32cd32' }} />
        <PercentageOutlined style={{ color: '#32cd32' }} />
      </Option>
      <Option value="down">
        <DownOutlined style={{ color: 'red' }} />
        <PercentageOutlined style={{ color: 'red' }} />
      </Option>
    </AntSelect>
  );
  return (
    <Space>
      <Checkbox
        onChange={onChange}
        checked={checked}
      ></Checkbox>
      <InputNumber
        disabled={!checked}
        style={{ width: '162px' }}
        min="0"
        addonBefore={selectBefore}
        defaultValue="0"
      />
    </Space>
  );
};

const TrailingStopLossControl = ({ updateLeg, data }: any) => {
  const [checked, setChecked] = useState(false);
  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
    setChecked(e.target.checked);
  };
  return (
    <Space>
      <Checkbox
        onChange={onChange}
        checked={checked}
      ></Checkbox>
      <Space.Compact>
        <Input
          disabled={!checked}
          prefix="X:"
          style={{ width: '72px' }}
          defaultValue=""
        />
        <Input
          disabled={!checked}
          prefix="Y:"
          style={{ width: '72px' }}
          defaultValue=""
        />
      </Space.Compact>
    </Space>
  );
};

const ExpiryCycleControl = ({ updateLeg, data }: any) => {
  const updateData = (val: string) => {
    data.expiryCycle = val;
    updateLeg?.(data);
  };
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    updateData(value);
  };
  const options = [
    { value: 'current-week', label: 'Current-Week' },
    { value: 'next-week', label: 'Next-Week' },
    { value: 'current-month', label: 'Current-Month' },
  ];

  return (
    <AntSelect
      defaultValue={data?.expiryCycle || 'current-week'}
      style={{ minWidth: 142 }}
      onChange={handleChange}
      options={options}
    />
  );
};
