import axios from 'axios';
import { URLS } from '../../constants';

export const getAllStragegiesForUser = async () => {
  const { data } = await axios.get(URLS.strategy.list);
  return data;
};

export const createStrategy = async (payload: any) => {
  console.log('Payload is ', payload);
  const { data } = await axios.post(URLS.strategy.create, payload);
  return data;
};

export const updateStrategy = async ({ payload }: any) => {
  const { data } = await axios.put(URLS.strategy.update, payload);
  return data;
};

export const deleteStrategy = async ({ payload }: any) => {
  const { data } = await axios.delete(URLS.strategy.delete, payload);
  return data;
};

export const getStrategyById = async (props: any) => {
  const strategyId = props.queryKey[1];
  const { data } = await axios.get(URLS.strategy.details + '/' + strategyId);
  return data;
};

export const getStrategyAuditLogs = async ({ payload }: any) => {
  const { data } = await axios.get(URLS.strategy.getAuditLogs, payload);
  return data;
};
