// Create a react component called StrategyList that will be used to list all the strategies, refer the website "quantman.in"
import React from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../AuthProvider';
// import { useQueryClient } from 'react-query';
import { getAllStragegiesForUser } from '../api/routes/strategy';
import { useQuery } from 'react-query';
// import components from @mui/material
import { List, Skeleton, Card, Dropdown, Button } from 'antd';
// import react icons
// import { FaTrash } from 'react-icons/fa';
// import { FaPlus } from 'react-icons/fa';
// import { red, green } from '@ant-design/colors';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';

// const PaperStyles = { mt: 2, pt: 2 };

const StrategyList = () => {
  // const navigate = useNavigate();
  // const auth = useAuth();
  // const queryClient = useQueryClient();
  const {
    data,
    // isLoading, error
  } = useQuery('strategies', getAllStragegiesForUser, {
    onSuccess: (res) => {
      console.log(res);
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log('onSettled');
    },
  });

  // const handleCreateStrategy = () => {
  //   navigate('/create-strategy');
  // };

  const onMenuClick: MenuProps['onClick'] = (e: any) => {
    console.log('click', e);
  };

  return (
    <>
      <Card
        title="My Strategies"
        style={{ width: '100%' }}
      >
        <div>
          <List
            className="demo-loadmore-list"
            // loading={initLoading}
            itemLayout="horizontal"
            // loadMore={loadMore}
            dataSource={data}
            renderItem={(item: any) => (
              <List.Item
                actions={[
                  <Button type="primary">Deploy Live</Button>,

                  <Dropdown.Button
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: 'Edit Strategy',
                        },
                        {
                          key: '2',
                          label: 'Delete Strategy',
                        },
                      ],
                      onClick: onMenuClick,
                    }}
                  >
                    Paper Trade
                  </Dropdown.Button>,
                  // <a key="list-loadmore-more">Backtest</a>,
                  // <a key="list-loadmore-more">more</a>,
                ]}
              >
                <Skeleton
                  avatar
                  title={false}
                  loading={item.loading}
                  active
                >
                  <List.Item.Meta
                    // avatar={<Avatar src={item.picture.large} />}
                    title={<Link to={item?.id}>{item.strategyName}</Link>}
                    description="Ant Design, a design language for background applications..."
                  />
                  {/* <div>
                    <Progress
                      percent={100}
                      steps={5}
                      size="small"
                      strokeColor={green[6]}
                    />
                  </div> */}
                </Skeleton>
              </List.Item>
            )}
          ></List>
        </div>
      </Card>
    </>
  );
};

export default StrategyList;
