import axios from 'axios';
import { URLS } from '../../constants';

export const getUserProfileData = async () => {
  const { data } = await axios.get(URLS.user.profile);
  return data;
};

export const getTradingAccountsForUser = async () => {
  const { data } = await axios.get(URLS.tradingAccounts.forUser);
  return data;
};

export const createTradingAccountForUser = async (payload: any) => {
  console.log(payload);
  const { data } = await axios.post(URLS.tradingAccounts.create, payload);
  return data;
};

export const updateTradingAccountForUser = async (data: any) => {
  const result = await axios.patch(URLS.user.profile, { ...data });
  return result;
};

export const deleteTradingAccountForUser = async (tradingAccountId: any) => {
  const result = await axios.delete(
    URLS.tradingAccounts.delete + tradingAccountId,
  );
  return result;
};

export const updateUserProfileData = async (data: any) => {
  const result = await axios.patch(URLS.user.profile, { ...data });
  return result;
};

export const processRequestToken = async (data: any) => {
  console.log(data);
  const result = await axios.post(
    URLS.tradingAccounts.processRequestToken,
    data,
  );
  return result;
};
