// import config from '../api/config';

const base_url = process.env.REACT_APP_API_BASE_URL;

console.log('Base url :: ' + base_url);

export const URLS = {
  test: `${base_url}/test`,
  user: {
    profile: `${base_url}/users/profile`,
    tradingAccounts: `${base_url}/users/tradingaccounts`,
  },
  tradingAccounts: {
    forUser: `${base_url}/tradingaccounts/foruser`,
    create: `${base_url}/tradingaccounts/create`,
    delete: `${base_url}/tradingaccounts/delete/`,
    processRequestToken: `${base_url}/tradingaccounts/processrequesttoken`,
  },
  brokers: {
    getAll: `${base_url}/brokers/getall`,
    getAllInstruments: `${base_url}/brokers/getallinstruments`,
  },
  strategy: {
    list: `${base_url}/strategies/list`,
    details: `${base_url}/strategies/details`,
    create: `${base_url}/strategies/create`,
    delete: `${base_url}/strategies/delete/`,
    update: `${base_url}/strategies/update`,
    getAuditLogs: `${base_url}/strategies/getlogs`,
  },
};
