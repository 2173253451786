// create a react component called CreateStrategy that will be used to create a new trading strategy
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useAuth } from '../AuthProvider';
// import { useQueryClient } from 'react-query';
import { createStrategy } from '../api/routes/strategy';
import { useMutation } from 'react-query';
// import components from @mui/material
import { Box } from '@mui/material';
import { InstrumentSearchAndSelect } from '../components/strategy/instrumentSelect';
import { LegsList } from '../components/strategy/legsList';
import {
  Select as AntSelect,
  Col,
  Form,
  InputNumber,
  Row,
  Space,
  Input,
  Button,
  Card,
  DatePicker,
  TimePicker,
} from 'antd';
import type { RadioChangeEvent } from 'antd';
// import { Steps } from 'antd';
import { Radio } from 'antd';
import EntryCondition from '../components/strategy/entryCondition';
import ExitCondition from '../components/strategy/exitCondition';

import type { Dayjs } from 'dayjs';
// import dayjs from 'dayjs';
// import customParseFormat from 'dayjs/plugin/customParseFormat';

const cardStyles = { width: '100%', marginBottom: '1em' };

const newStrategy = {
  strategyName: '',
  strategyGroupName: '',
  strategyType: 'intraday',
  instrumentType: 'fno',
  instrument: '',
  legs: [],
  entryConditions: [],
  exitConditions: [],
  expiry: { type: 'auto', settings: {} },
  overallStopLoss: { type: 'rupees', value: '' },
  overallTakeProfit: { type: 'rupees', value: '' },
  strikeBasedOn: 'fbsi',
  deployment: { type: 'backtest', settings: {} },
};

const CreateStrategy = (props: any) => {
  const [strategy, setStrategy] = useState(props?.strategy || newStrategy);
  // const [strategyName, setStrategyName] = useState('');
  // const [strategyGroupName, setStrategyGroupName] = useState('');
  // const [strategyType, setStrategyType] = useState('intraday');
  // const [instrumentType, setInstrumentType] = useState('fno');
  // const [instrument, setInstrument] = useState('');
  // const [legs, setLegs] = useState([]);
  // const [entryCondition, setEntryCondition] = useState({});
  // const [exitCondition, setExitCondition] = useState({});

  const navigate = useNavigate();
  // const auth = useAuth();
  // const queryClient = useQueryClient();
  const { mutate: doStrategyCreate, isLoading } = useMutation(createStrategy, {
    onSuccess: (res) => {
      const { data } = res;
      if (!!data?.strategyId) {
        navigate(`/strategy/${data.strategyId}`);
      }
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log('onSettled');
    },
  });

  const handleCreateStrategy = () => {
    const payload = { ...strategy };
    doStrategyCreate(payload);
  };

  useEffect(() => {
    console.log('strategy', strategy);
  }, [strategy]);

  const commonProps = {
    data: strategy,
    setData: setStrategy,
  };

  return (
    <Box
      // onSubmit={handleCreateStrategy}
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <Card
        size="small"
        title="Basic Details"
        style={cardStyles}
      >
        <Box sx={{ margin: '1em' }}>
          <Form
            labelCol={{ span: 6 }}
            layout="horizontal"
          >
            <Row>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  name="strategyName"
                  label="Strategy Name"
                >
                  <Input
                    name="strategyName"
                    id="strategyName"
                    defaultValue={strategy.strategyName}
                    onChange={(e) => {
                      setStrategy({
                        ...strategy,
                        strategyName: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="strategyGroupName"
                  label="Group Name"
                >
                  <Input
                    name="strategyGroupName"
                    id="strategyGroupName"
                    defaultValue={strategy.strategyGroupName}
                    onChange={(e) => {
                      setStrategy({
                        ...strategy,
                        strategyGroupName: e.target.value,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                lg={{ span: 12 }}
              >
                <Form.Item
                  name="strategyType"
                  label="Strategy Type"
                >
                  <StrategyType {...commonProps} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Box>
      </Card>

      <Card
        size="small"
        title="Instrument Details"
        style={cardStyles}
      >
        <Box sx={{ margin: '1em' }}>
          <InstrumentSearchAndSelect {...commonProps} />
        </Box>
      </Card>

      <EntryCondition
        {...commonProps}
        style={cardStyles}
      />

      <LegsList
        {...commonProps}
        style={cardStyles}
      />

      <ExitCondition
        {...commonProps}
        style={cardStyles}
      />

      <Card
        size="small"
        title="Overall Stop Loss and Take Profit"
        style={cardStyles}
      >
        <Box sx={{ margin: '1em' }}>
          <OverallStopAndExpiry {...commonProps} />
        </Box>
      </Card>

      <Card
        size="small"
        title="Submit"
        style={cardStyles}
      >
        <Box sx={{ margin: '1em' }}>
          <DeploymentTypeSubmit
            {...commonProps}
            handleCreateStrategy={handleCreateStrategy}
            isLoading={isLoading}
          />
        </Box>
      </Card>
    </Box>
  );
};

export default CreateStrategy;

const StrategyType = ({ data, setData }: any) => {
  const options = [
    { value: 'intraday', label: 'Intraday' },
    { value: 'positional', label: 'Positional' },
  ];
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    setData?.({ ...data, strategyType: value });
  };
  return (
    <AntSelect
      defaultValue={data?.strategyType || 'intraday'}
      style={{ width: 142 }}
      onChange={handleChange}
      options={options}
    />
  );
};

const ExpiryType = ({ data, setData }: any) => {
  // const updateData = (val: string) => {
  //   data.expiryCycle = val;
  //   updateLeg?.(data);
  // };
  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
    const payload = {
      ...data,
      expiry: { ...data.expiry, type: value, settings: {} },
    };
    setData(payload);
  };
  const options = [
    { value: 'auto', label: 'Auto Expiry' },
    { value: 'manual', label: 'Manual Expiry' },
  ];

  function range(start: number, end: number) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  // type DisabledTime = (now: Dayjs) => {
  //   disabledHours?: () => number[];
  //   disabledMinutes?: (selectedHour: number) => number[];
  //   disabledSeconds?: (
  //     selectedHour: number,
  //     selectedMinute: number,
  //   ) => number[];
  // };

  const disabledTimes = (now: Dayjs): any => {
    const hours = [...range(0, 9), ...range(16, 24)];
    const minutes = range(0, 60);
    const seconds = range(0, 60);
    return {
      disabledHours: () => hours,
      // disabledHours: () => hours.filter((hour) => hour < now.hour()),
      disabledMinutes: (selectedHour: any) =>
        selectedHour === 9
          ? minutes.filter((minute) => minute < 15)
          : selectedHour === 15
          ? minutes.filter((minute) => minute > 28)
          : [],
      disabledSeconds: (selectedHour: any, selectedMinute: any) =>
        selectedHour === now.hour() && selectedMinute === now.minute()
          ? seconds.filter((second) => second < now.second())
          : [],
    };
  };

  return (
    <>
      <AntSelect
        defaultValue={data?.expiryStrategy || 'auto'}
        style={{ minWidth: 142 }}
        onChange={handleChange}
        options={options}
      />
      {data?.expiry?.type === 'manual' ? (
        <Space
          direction="vertical"
          style={{ marginTop: '8px' }}
        >
          <Form.Item
            name="exitAt"
            label="Exit At"
          >
            <TimePicker
              disabledTime={disabledTimes}
              // style={{ ...props?.style }}
              onChange={(e: any) => {
                const time = e?.format('HH:mm:ss');
                console.log('time - ', time);
                const payload = {
                  ...data.expiry,
                  settings: { ...data.expiry.settings, exitAt: time },
                };
                setData({ ...data, expiry: payload });
              }}
            />
          </Form.Item>
          <Form.Item
            name="daysBeforeExpiry"
            label="Days Before Expiry"
          >
            <InputNumber
              min={1}
              max={10}
              onChange={(e: any) => {
                const payload = {
                  ...data.expiry,
                  settings: { ...data.expiry.settings, daysBeforeExit: e },
                };
                setData({ ...data, expiry: payload });
              }}
            />
          </Form.Item>
        </Space>
      ) : (
        <Space
          direction="vertical"
          style={{ marginTop: '8px' }}
        >
          <Form.Item
            name="exitAt"
            label="Exit At (On Expiry Day)"
          >
            <TimePicker
              // style={{ ...props?.style }}
              onChange={(e: any) => {
                const time = e?.format('HH:mm:ss');
                console.log('time - ', time);
                const payload = {
                  ...data.expiry,
                  settings: { ...data.expiry.settings, exitAt: time },
                };
                setData({ ...data, expiry: payload });
              }}
            />
          </Form.Item>
        </Space>
      )}
    </>
  );
};

const OverallStopAndExpiry = ({ data, setData }: any) => {
  const { Option } = AntSelect;

  const stopLossSelectBefore = (
    <AntSelect
      defaultValue={data?.overallStopLoss?.type || 'percent'}
      style={{ minWidth: '56px' }}
    >
      <Option value="percent">%</Option>
      <Option value="rupees">₹</Option>
    </AntSelect>
  );

  const takeProfitSelectBefore = (
    <AntSelect
      defaultValue={data?.overallTakeProfit?.type || 'percent'}
      style={{ minWidth: '56px' }}
    >
      <Option value="percent">%</Option>
      <Option value="rupees">₹</Option>
    </AntSelect>
  );

  return (
    <Form
      labelCol={{ span: 8 }}
      // wrapperCol={{ span: 14 }}
      layout="horizontal"
    >
      <Row>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 12 }}
        >
          <Form.Item
            name="overall-stoploss"
            label="Overall Stoploss"
          >
            <InputNumber
              // style={{ width: '162px' }}
              min="0"
              addonAfter={stopLossSelectBefore}
              defaultValue={data?.overallStopLoss?.value || '0'}
            />
          </Form.Item>
          <Form.Item
            name="overall-takeprofit"
            label="Overall Take Profit"
          >
            <InputNumber
              // style={{ width: '162px' }}
              min="0"
              addonAfter={takeProfitSelectBefore}
              defaultValue={data?.overallTakeProfit?.value || '0'}
            />
          </Form.Item>
        </Col>
        <Col
          xs={{ span: 24 }}
          lg={{ span: 12 }}
        >
          {data?.strategyType === 'intraday' ? (
            <>
              <Form.Item
                name="expiry-type"
                label="Trade Between"
              >
                <TimePicker.RangePicker />
              </Form.Item>
              <Form.Item
                name="maxTransactionsPerDay"
                label="Max Transaction Per Day"
              >
                <InputNumber
                  min={1}
                  max={10}
                  onChange={(e: any) => {
                    const payload = {
                      ...data.expiry,
                      settings: { ...data.expiry.settings, daysBeforeExit: e },
                    };
                    setData({ ...data, expiry: payload });
                  }}
                />
              </Form.Item>
            </>
          ) : (
            <Form.Item
              name="expiry-type"
              label="Expiry Type"
            >
              <ExpiryType
                data={data}
                setData={setData}
              />
            </Form.Item>
          )}
        </Col>
      </Row>
    </Form>
  );
};

const DeploymentTypeSubmit = ({
  data,
  setData,
  handleCreateStrategy,
  isLoading,
}: any) => {
  const options = [
    { label: 'Backtest', value: 'backtest', buttonLabel: 'Create Backtest' },
    { label: 'Live', value: 'live', buttonLabel: 'Run Live' },
    { label: 'Paper', value: 'paper', buttonLabel: 'Paper Trade' },
  ];
  const [deployment, setDeployment] = useState(
    data?.deployment?.type || 'backtest',
  );

  const onChange = ({ target: { value } }: RadioChangeEvent) => {
    setDeployment(value);
    setData({ ...data, deployment: { type: value } });
  };

  const validateStrategy = (data: any) => {
    const {
      // strategyName: '',
      // strategyGroupName: '',
      // strategyType: 'intraday',
      // instrumentType: 'fno',
      // instrument: '',
      // legs: [],
      // entryConditions: [],
      // exitConditions: [],
      // expiry: { type: 'auto', settings: {} },
      // overallStopLoss: { type: 'rupees', value: '' },
      // overallTakeProfit: { type: 'rupees', value: '' },
      // strikeBasedOn: 'fbsi',
      // deployment: { type: 'backtest', settings: {} },

      // strategyName,
      // strategyGroupName,
      // instrumentType,
      strategyType,
      // instrument,
      // legs,
      // entryConditions,
      // exitConditions,
      // expiry: { type: expiryType, settings: expirySettings },
      // overallStopLoss: { type: stopLossType, value: stopLossValue },
      // overallTakeProfit: { type: takeProfitType, value: takeProfitValue },
      // strikeBasedOn,
      // deployment: { type: deployType, settings: deploySettings },
    } = data;

    if (strategyType === 'intraday') {
      if (deployment.type === 'backtest') {
        console.log('intraday backtest');
      } else if (deployment.type === 'live') {
        console.log('intraday live');
      } else if (deployment.type === 'paper') {
        console.log('intraday paper');
      }
    } else if (strategyType === 'positional') {
      if (deployment.type === 'backtest') {
        console.log('positional backtest');
      } else if (deployment.type === 'live') {
        console.log('positional live');
      } else if (deployment.type === 'paper') {
        console.log('positional paper');
      }
    }

    console.log('validate strategy', data);
    return true;
  };

  const onSubmit = () => {
    console.log('submit');
    const isValid = validateStrategy(data);
    if (isValid) {
      handleCreateStrategy();
    } else {
      console.log('invalid strategy');
    }
  };

  return (
    <>
      <Form
        // labelCol={{ span: 6 }}
        layout="horizontal"
      >
        <Row>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 8 }}
          >
            <Form.Item
              name="deployType"
              label="Deployment Type"
            >
              <Radio.Group
                options={options}
                onChange={onChange}
                value={deployment}
                defaultValue={data?.deployment?.type || 'backtest'}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </Col>
          <Col
            xs={{ span: 24 }}
            lg={{ span: 11, offset: 1 }}
          >
            {deployment === 'backtest' && (
              <Form.Item
                name="backtestTimeRange"
                label="Time Range"
              >
                <DatePicker.RangePicker />
              </Form.Item>
            )}
          </Col>

          <Col
            xs={{ span: 24 }}
            lg={{ span: 2, offset: 1 }}
          >
            <Button
              loading={isLoading}
              style={{ marginLeft: '20px', float: 'right' }}
              type="primary"
              onClick={() => {
                onSubmit();
              }}
            >
              {options.find((item) => item.value === deployment)?.buttonLabel}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};

// const ProcessSteps = () => {
//   const [current, setCurrent] = useState(0);

//   const onChange = (value: number) => {
//     console.log('onChange:', value);
//     setCurrent(value);
//   };
//   const description = 'This is a description.';

//   return (
//     <Steps
//       current={current}
//       onChange={onChange}
//       direction="vertical"
//       items={[
//         {
//           title: 'Step 1',
//           description,
//         },
//         {
//           title: 'Step 2',
//           description,
//         },
//         {
//           title: 'Step 3',
//           description,
//         },
//       ]}
//     />
//   );
// };
