import React, { useEffect } from 'react';
import {
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from 'firebase/auth';
import { auth } from './firebase';

interface AuthContextType {
  user: any;
  signin: (user: any, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  let signin = (newUser: any, callback: VoidFunction) => {
    //   return fakeAuthProvider.signin(() => {
    //     setUser(newUser);
    //     callback();
    //   });

    return signInWithEmailAndPassword(auth, newUser?.email, newUser?.password)
      .then((userCredential) => {
        // Signed in
        const newUser = userCredential.user;
        setUser(newUser);
        callback?.();
        // navigate('/');
        console.log(newUser);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  };

  let signout = (callback: VoidFunction) => {
    return signOut(auth).then(() => {
      setUser(null);
      callback?.();
    });
  };

  useEffect(() => {
    onAuthStateChanged(auth, (_user: any) => {
      console.log('User state changed!');
      setUser(_user);
    });
  }, []);

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

export { AuthProvider, useAuth };
