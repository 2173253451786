import React from 'react';
import { useAuth } from '../AuthProvider';
// import { getTestData } from '../api/routes/dashboard';

const Home = () => {
  const auth = useAuth();

  // const queryClient = useQuery('test', getTestData, {
  //   refetchOnWindowFocus: false,
  //   enabled: auth?.user?.emailVerified,
  // });

  return (
    <>
      <nav>
        <p>Welcome Home {auth?.user?.email}</p>
      </nav>
    </>
  );
};

export default Home;
