import React, { useState } from 'react';
// import { signInWithEmailAndPassword } from 'firebase/auth';
// import { auth } from '../firebase';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useAuth } from '../AuthProvider';

const Signin = (props: any) => {
  const { navOnSuccess } = props;
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  let from = location.state?.from?.pathname || '/';

  function onLogin(event: any) {
    event.preventDefault();

    auth.signin({ email, password }, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      if (!!navOnSuccess) {
        navigate(navOnSuccess, { replace: true });
      } else {
        navigate(from, { replace: true });
      }
    });
  }

  return (
    <>
      <main>
        <section>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '2em',
            }}
          >
            <div
              style={{
                width: '300px',
                height: '400px',
              }}
            >
              <p style={{ textAlign: 'center' }}> Market sage </p>

              <form>
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  variant="standard"
                  id="email-address"
                  label="Email"
                  name="email"
                  type="email"
                  required
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  fullWidth
                  sx={{ mb: 2 }}
                  label="Password"
                  variant="standard"
                  id="password"
                  name="password"
                  type="password"
                  required
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Button
                  fullWidth
                  sx={{ mb: 2 }}
                  onClick={onLogin}
                >
                  Login
                </Button>
              </form>

              <p className="text-sm text-white text-center">
                No account yet? <NavLink to="/signup">Sign up</NavLink>
              </p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Signin;
